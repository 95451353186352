.ql-toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.ql-toolbar .ql-formats {
    display: flex;
    align-items: center;
    margin-right: 15px; /* Optional: Adds space between groups */
}

.ql-customLink::before {
    content: 'Phishinglink';
}

.ql-customPDF::before {
    content: 'Phishing-PDF';
}

.ami-editor {
    height: 100%;
}

.ami-editor .ql-editor {
    height: 100vh;
    min-height: 50%;
}

.ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
    font-family: "Arial", sans-serif;
    content: "Arial";
}

.ql-picker.ql-font .ql-picker-label[data-value="Verdana"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Verdana"]::before {
    font-family: "Verdana", sans-serif;
    content: "Verdana";
}

.ql-picker.ql-font .ql-picker-label[data-value="Calibri"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Calibri"]::before {
    font-family: "Calibri", sans-serif;
    content: "Calibri";
}

.ql-font-Arial {
    font-family: "Arial";
}

.ql-font-Verdana {
    font-family: "Verdana";
}

.ql-font-Calibri {
    font-family: "Calibri";
}
