.wen-muss-ich {
  flex: 1;
  position: relative;
}

.question-image {
  position: relative;
  align-self: center;
}

.overflowQuestions {
  overflow: auto;
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
}


.fragen-section1,
.fragen-text {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.fragen-text {
  border-radius: 5px;
  background-color: #fff;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  padding: 0 16px;
}

.fragen-section1 {
  overflow-y: auto;
  flex: 1;
  border: 3px solid #000;
  flex-direction: column;
  padding: 10px 30px;
  gap: 20px;
  text-align: center;
  font-size: 24px;
  color: #000;
  font-family: "Verdana";
}

@media screen and (max-width: 960px) {
  .wen-muss-ich {
    flex: unset;
    align-self: stretch;
  }

  .fragen-text {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0;
  }
}

@media screen and (max-width: 420px) {
  .wen-muss-ich {
    flex: unset;
    align-self: stretch;
  }

  .fragen-text {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

/* Container for all answers */
.answers-container {
  display: flex;
  flex-direction: column;
}

/* Container for image answers */
.image-answers {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 images per row */
  gap: 10px;
}

/* Container for text answers */
.text-answers {
  display: flex;
  flex-direction: column;
  margin-top: 20px; /* space between image and text answers */
}

/* Style for each image answer */
.image-answer {
  border: 1px solid #000;
  border-radius: 5px;
  padding: 10px;
}

/* Style for each text answer */
.text-answer {
  border: 1px solid #000;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px; /* space between text answers */
}
